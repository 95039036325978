import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import TextHeader from '../components/TextHeader';
import VideoCard from '../components/VideoCard';
import Seo from '../components/Seo';

const Category = ({ data }) => {
    const { nodes, totalCount } = data.allVideosJson;
    const { categoryName, categoryPreview } = data.allCategoriesJson.nodes[0];

    const models = data.allModelsJson.nodes;

    const subtitle = `View all ${totalCount} video${
        totalCount === 1 ? '' : 's'
    } on FemScat tagged with ${categoryName}.`;

    return (
        <Layout {...data.site.siteMetadata}>
            <Seo
                pageTitle={categoryName}
                description={`See all ${totalCount} ${categoryName} videos from CassieScat, AnalAddicted and all your other favorite scat models, only on KinkyScat.com!`}
                image={categoryPreview}
                {...data.site.siteMetadata}
            />
            <TextHeader
                title={`All ${categoryName} Videos`}
                subtitle={subtitle}
            />

            <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:max-w-none">
                {nodes.map(video => {
                    const model = models.find(m => m.storeId === video.storeId);

                    // Some videos do not have a model, as that model has been removed from the site
                    if (!model) {
                        return undefined;
                    }

                    return (
                        <VideoCard
                            key={`${video.storeId}+${video.videoId}`}
                            video={video}
                            model={model}
                        />
                    );
                })}
            </div>
        </Layout>
    );
};

export default Category;

export const pageQuery = graphql`
    query CategoryQuery($categoryId: String) {
        site {
            siteMetadata {
                title
                mainSiteUrl
                social {
                    twitter
                    reddit
                    rss
                }
            }
        }
        allCategoriesJson(filter: { categoryId: { eq: $categoryId } }) {
            nodes {
                categoryName
                categoryPreview
            }
        }
        allVideosJson(
            filter: {
                categories: { elemMatch: { categoryId: { in: [$categoryId] } } }
            }
        ) {
            totalCount
            nodes {
                storeId
                videoId
                title
                description
                preview
                categories {
                    categoryId
                    categoryName
                }
                link
                date
            }
        }
        allModelsJson {
            nodes {
                storeId
                name
                store
                avatar
            }
        }
    }
`;
